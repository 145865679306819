import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { getCookie } from '../utils/cookie.js'
import DocumentMeta from 'react-document-meta';

import AppContext from '../contexts/AppContext.js'; 

class Login extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            username: "",
            password: "",

            isRegistrationEnabled: false
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentWillMount() {
        var registration = false;

        if (process.env.NODE_ENV === 'production') {
            var cookie = getCookie("registration");
            registration = (cookie == "true");
        }

        var company = getCookie("company");

        this.setState({isRegistrationEnabled: registration, company: decodeURIComponent(company)});
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    loginClick() {
        this.login();
    }

    login() {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/login/auth', { login: this.state.username, password: this.state.password }).then((res) => {
            window.location.reload();
        }).catch((error) => {
            if (error.response.status == 401) {
                //delete cookie
                //window.location.reload();
                addNotification(error.response.data, 2);
            } else {
                addNotification(error.response.data, 2);
            }
        }).finally(function () {
            removeNotification(u);
        });
    }

    getRegistrationURL() {
        var url = window.location.href;
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        console.log(hostname + "/registration");

        return hostname + "/registration";
    }

    render() {
        const meta = {
			title: "Авторизацiя",
			description: "Авторизацiя",
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
            
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">

                            <div className="text-center mb-2">
                                <span className="display-4" style={{fontSize: '1.2rem'}}><b>{this.state.company}</b></span>
                            </div>

                            <div className="card agis-card mb-2">
                                <div className="header">
                                    <span>Вiдомостi про користувача</span>
                                </div>
                                <div className="body">
                                    <div className="row">
                                        <div className="col-md-4 agis-input-label">
                                            e-mail (логiн)
                                        </div>
                                        <div className="col-md-8">
                                            <input name="username" type="text" autocomplete="username" className="agis-input" onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} value={this.state.username} />
                                        </div>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="row">
                                        <div className="col-md-4 agis-input-label">
                                            Пароль
                                        </div>
                                        <div className="col-md-8">
                                            <input name="password" type="password" className="agis-input" onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} value={this.state.password} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 mb-4">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.state.isRegistrationEnabled ? (
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <Link to={"/restore"} class="btn btn-agis-link">Нагадати пароль</Link>
                                            <a href={"http://"+this.getRegistrationURL()} class="btn btn-agis-link">На сторiнку реєстрацiї</a>
                                        </div>
                                        <div>
                                            <button className="btn btn-agis" onClick={this.loginClick.bind(this)}>Увiйти</button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-between">
                                    <Link to={"/restore"} class="btn btn-agis-link">Нагадати пароль</Link>
                                    <button className="btn btn-agis" onClick={this.loginClick.bind(this)}>Увiйти</button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row mt-2 mb-4">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="d-flex justify-content-center">
                                <a href="https://www.facebook.com/UkraineWR/" target="_blank" className="text-secondary">
                                    <div className="d-flex align-items-center">
                                        <svg style={{height: "20px", marginRight: "10px"}} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-facebook-square fa-w-14 fa-3x"><path fill="currentColor" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg> 
                                        <div style={{height: "20px", lineHeight: "22px"}}>Ми в Facebook!</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;