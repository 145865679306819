import React from 'react';
import uuid from 'uuid';
import AppContext from "./AppContext.js";

class AppContextProvider extends React.Component {
    state = {
        text: "some text",
        notifications: [
            // {id: "", type: 1, text: "text"},
            // {id: "", type: 1, text: "text"}
        ],

        addNotification: (text, type) => {
            if (type === undefined) {
                type = 1;
            }
            var u = uuid();
            var object = {id: u, type: type, text: text};
    
            var newNotifications = this.state.notifications;
            newNotifications.push(object);
            this.setState({notifications: newNotifications});
            return u;
        },

        removeNotification: (id) => {
            var newNotifications = [];
            for (var i = 0; i < this.state.notifications.length; i++) {
                if (this.state.notifications[i].id !== id) {
                    newNotifications.push(this.state.notifications[i]);
                }
            }
            this.setState({notifications: newNotifications});
        }
    }

    render() {
        return (
            <AppContext.Provider value={{...this.state}}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppContextProvider;