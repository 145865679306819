import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AppContext from '../contexts/AppContext';

class Notification extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
		}
    }

    render() {
        const {notifications, removeNotification} = this.context;

        var nlist = notifications.map((item, i) => {
            switch (item.type) {
                //Error
                case 2: 
                    return (
                        <span key={item.id}>
                            <span className="agis-error mb-1" style={{display: 'inline-block'}}>
                                {item.text}
                                <button className="btn btn-agis-link" style={{padding: 'none', paddingBottom: '2px', fontSize: '10px', minWidth: '20px', color: '#222'}} onClick={() => removeNotification(item.id)}><FontAwesomeIcon icon={faTimes}/></button>    
                            </span>
                            <br/>
                        </span>
                    );
                //Warning/Loading
                default:
                    return (
                        <span key={item.id}>
                            <span className="agis-warning mb-1" style={{display: 'inline-block'}}>{item.text}</span>
                            <br/>
                        </span>
                    );
            }
        });

        return (
            <div className="fixed-top text-center" style={{position: 'fixed', left:'calc(50% - 100px)', width: '200px'}}>
                <br/><br/>
                {nlist}
            </div>
        )
    }
}

export default Notification;