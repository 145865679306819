import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Notification from './components/notification.js';
import AppContextProvider from './contexts/AppContextProvider.js';

import Login from './pages/login.js';
import Restore from './pages/restore.js';

import './css/bootstrap.min.css';
import './css/app.css';
import './css/agis.css';
import './css/error.css';

import './css/menu.css';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <AppContextProvider>
                <BrowserRouter basename={"/login"}>
                    <div>
                        <Notification />

                        <Switch>
                            <Route exact path={`/`} component={Login} />
                            <Route exact path={`/restore`} component={Restore} />
                        </Switch>
                    </div>
                </BrowserRouter>
            </AppContextProvider>
        );
    }
}

export default App;