import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { getCookie } from '../utils/cookie.js'
import DocumentMeta from 'react-document-meta';

import AppContext from '../contexts/AppContext.js'; 

class Restore extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            company: "",

            email: "",
            serror: "",
            isSent: false
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillMount() {
        var company = getCookie("company");
        this.setState({company: decodeURIComponent(company)});
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            serror: "",
            isSent: false
        });
    }

    loginClick() {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/login/restore', { email: this.state.email }).then((res) => {
            this.setState({serror: res.data.serror, isSent: true});
        }).catch((error) => {
            if (error.response.status == 401) {
                //delete cookie
                //window.location.reload();
                addNotification(error.response.data, 2);
            } else {
                addNotification(error.response.data, 2);
            }
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const meta = {
			title: "Нагадування пароля",
			description: "Нагадування пароля",
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="text-center mb-2">
                                <span className="display-4" style={{fontSize: '1.2rem'}}><b>{this.state.company}</b></span>
                            </div>

                            <div className="card agis-card mb-2">
                                <div className="header">
                                    <span>Вiдомостi про користувача</span>
                                </div>
                                <div className="body">
                                    <div className="row">
                                        <div className="col-md-4 agis-input-label">
                                            e-mail
                                        </div>
                                        <div className="col-md-8">
                                            <input name="email" type="text" autoComplete="new-password" className="agis-input" onChange={this.handleInputChange} value={this.state.email} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.serror != "" ? (
                            <div className="row">
                                <div className="col text-center">
                                    <small className="text-danger">{this.state.serror}</small>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col text-center">
                                    {
                                        this.state.isSent ? (
                                            <small className="text-success">Повідомлення відправлено на {this.state.email}</small>
                                        ) : (
                                            <small className="text-success"></small>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }

                    <div className="row mt-2 mb-4">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="d-flex justify-content-between">
                                <Link to={"/"} class="btn btn-agis-link">На сторiнку авторизацiї</Link>
                                <button className="btn btn-agis" onClick={this.loginClick.bind(this)}>Нагадати</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Restore;